@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* common css  */
:root {
  --primary: #C700E3;
  --secondary: #91458f;
  --primary-light: #fdd7bd;
  --expired: #ed4040;
  --expired-light: #f05c5c;
  --black: #000;
  --menuItemLink: #e6e6e6;
  --black3: #0003;
  --body-bg: rgb(30,33,66);
  --dark-background: #111736;
  --card-background: #25284B;
  --card-some_how_light-background: #1E2141;
  --card-some_how_more_light-background: #545bab;
  --light: #e6e6e6;
  --lightArrow: url('./../../ASSETS/Images/icons/arrow_2.png');
  --darkArrow: url('./../../ASSETS/Images/icons/arrow_1.png');
  --primaryArrow: url('./../../ASSETS/Images/xr/down-arrow.png');
  --close: url('./../../ASSETS/Images/icons/cancel.png');
  --close: url('./../../ASSETS/Images/icons/cancel.png');
  --closelight: url('./../../ASSETS/Images/xr/close-light.png');
  --toggler: url('./../../ASSETS/Images/icons/burger.png');
  --imageShadow: url('./../../ASSETS/Images/xr/imageShado.png');
  --arrowImage: url('./../../ASSETS/Images/xr/arrow.png');
  --border: url('./../../ASSETS/Images/xr/rectangle6741.png');
  --listbg: url('./../../ASSETS/Images/xr/rectangle6742.png');
}

.text-primary {
  color: var(--primary) !important;
}
.text-secondary {
  color: var(--secondary) !important;
}
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--body-bg);
  font-size: 0.9rem;
  color: #ebebeb !important;
}

.pageTitle h5 {
  font-size: 1.3rem;
}

.btn-primary {
  background: var(--primary);
  border-color: var(--primary);
  box-shadow: 0px 0px 10px 0px var(--black3);
}

.btn-outline-primary {
  border-color: var(--primary);
  color: var(--primary);
}

.btn:hover,
.btn:focus-visible,
.btn:active,
.btn:first-child:active,
.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  background: var(--secondary);
  border-color: var(--secondary);
  box-shadow: none;
  color: #fff;
}

.lead {
  font-weight: 400;
}

.modal-medium {
  max-width: 400px;
  margin: 0 auto;
  width: calc(100% - 20px);
}

.secondary {
  text-decoration: none;
  color: var(--secondary);
}

.h-100vh {
  height: 100vh;
}

.w-60 {
  width: 60% !important;
}

a {
  text-decoration: none;
}

hr {
  opacity: 0.7;
  margin: 0.8rem 0;
}

.w-60 {
  width: 60%;
}

.profile-sm {
  border-radius: 50px;
  height: 45px;
  width: 45px;
  margin-right: 13px;
  object-fit: cover;
}

.img-thumbnail {
  border: none;
  padding: 0;
  width: 160px;
  min-width: 160px;
  border-radius: 12px;
  height: 160px;
  object-fit: cover;
  display: inline-block;
}

.profile-large {
  max-width: 160px;
  position: relative;
  height: 160px;
  object-fit: cover;
}

.profile-medium {
  width: 100px;
  min-width: 100px;
  height: 100px;
  object-fit: cover;
}

.cursor-pointer {
  cursor: pointer;
}

.mobile-profile p {
  max-width: 240px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.profile-large .edit_profile {
  position: absolute;
  bottom: -7px;
  right: -10px;
  cursor: pointer;
}

.btn-outline-dark {
  border: 1px solid var(--light);
}

/* form css  */
.form-select:not(.select_secondary) {
  background-image: var(--lightArrow);
  background-size: 14px;
  padding-right: 40px !important;
}

.form-select option:hover {
  background: var(--primary);
}

.form-check-input {
  box-shadow: none !important;
}

.form-check-input:checked {
  background: var(--primary);
  border-color: var(--primary);
}

.form-control::placeholder {
  color: var(--light);
}

.form-error {
  border-color: red !important;
}

/* loader css  */
.loader {
  position: fixed;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0000009c;
  flex-direction: column;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 11px;
  height: 11px;
  border-radius: 20px;
  background: #fff;
  /* border: 1px solid #fff; */
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* Login screen css  */
.login-container {
  background: var(--dark-background);
}

.login-form .mb-3 {
  margin-bottom: 13px !important;
}
.email-verification hr {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.2;
}
.password-field span {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  padding: 14px 10px;
  line-height: 9px;
}

.password-field {
  position: relative;
}

.form-control:focus {
  box-shadow: none;
}

.login-container form {
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
}

.password-field span img {
  max-width: 18px;
  height: 15px;
  object-fit: contain;
  object-position: center;
}

.login-form,
.login-content {
  /* min-height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background: var(--body-bg);
  border-radius: 15px;
  width: 22%
}

.auth-background-image{
  /* background-image: url(./../Images/authenTicationBI.jpg); */
  background-image: url(./../Images/authenTicationBI.jpg);
  min-height: 100vh;
  /* background-size: cover; */
  background-repeat: no-repeat;
  background-size: 100vw 100%;
}

.login-content {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom left;
}

.copyright {
  position: absolute;
  bottom: 15px;
}

.password-field input {
  padding-right: 30px;
}

.login-content h4 {
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
  max-width: 501px;
}

.email-verification h4 {
  font-size: 1.5em;
}

.email-verification {
  margin-left: auto;
  margin-right: auto;
  max-width: 320px;
}

/* header css  */
span.navbar-toggler-icon {
  background-image: var(--toggler);
  width: 1.4em;
  opacity: 0.8;
}
.header-bottom .d-flex {
  flex-flow: wrap;
}

h2.header-title {
  position: absolute;
  left: 50%;
  top: calc(50% - 40px);
  transform: translate(-50%, -50%);
  color: #fff;
  font-weight: 600;
}

.container-fluid {
  position: relative;
}
.headerbg {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  min-height: 18.75rem;
  opacity: 1;
  color: rgb(52, 71, 103);
  /* background: linear-gradient(310deg, rgba(33, 82, 255, 0), rgba(33, 212, 253, 0)) 50% center / cover, url(./../Images/xr/bg.jpg) transparent; */
  background: linear-gradient(310deg, rgba(33, 82, 255, 0), rgba(33, 212, 253, 0)) 50% center / cover, url(./../Images/threeSixtyBanner.jpg) transparent;
  border-radius: 1rem;
  background-position: 50% center;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
}

.header-bottom {
/* color: rgba(0, 0, 0, 0.87); */
transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
overflow: hidden;
display: flex;
flex-direction: column;
min-width: 0px;
overflow-wrap: break-word;
background-clip: border-box;
border: 0px solid rgba(0, 0, 0, 0.125);
border-radius: 1rem;
backdrop-filter: saturate(200%) blur(30px);
/* background-color: rgba(255, 255, 255, 0.8); */
background: var(--card-background);
box-shadow: var(--card-background) 0rem 0rem 0.0625rem 0.0625rem inset, rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
position: relative;
margin-top: -64px;
margin-left: 24px;
margin-right: 24px;
padding: 16px;
}
.profile-md{
  position: relative;
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0rem 0.3125rem 0.625rem 0rem;
  width: 4.625rem;
  height: 4.625rem;
}
.profile-md img{
  border-radius: 0.75rem;
}
.header-profile .userName {
  color: #fff !important;
  min-width: 60px;
  display: inline-block;
  color: var(--black);
  font-weight: 500;
  width: 100%;
  font-size:18px;
}
/* .header .container-fluid {
  padding: 0;
} */
.font-medium {
  font-weight: 400 !important;
}
.userProfile {
  position: relative;
  display: inline-block;
}

.header-profile.nav-item a {
  display: flex;
  align-items: center;
  line-height: 20px;
}
.user-expired .profile-sm,.user-expired .profile-medium {
  border: 2px solid #ed4040;
}
.user-expired .userPlan {
  background: #ed4040;
}

.expired-background {
  background: var(--expired);
}

.expired-background:hover {
  background: var(--expired-light);
}

.user-canceled .profile-sm,.user-canceled .profile-medium {
  border: 2px solid #EB9A00;
}
.user-canceled .userPlan {
  background: #EB9A00;
}

.mw-400 {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.user-premium .profile-sm, .user-premium .profile-medium {
  border: 2px solid var(--primary);
}
.user-free .profile-sm, .user-free .profile-medium {
  border: 2px solid #909090;
}
.sub-vertically-center {
  min-height: calc(100dvh - 200px);
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.table-striped > tbody > tr:nth-of-type(even) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: #ebebeb;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--card-some_how_light-background);
  color: #ebebeb;
}
button.react-date-picker__calendar-button svg {
  opacity: 0;
}

button.react-date-picker__calendar-button {
  background: url(./../Images/xr/calandar.png);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
}
.table-striped th {
  background: var(--bs-table-striped-bg);
  color: #ebebeb;
}
.mw-500 {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.userPlan {
  font-size: 10px;
  color: #fff;
  background: var(--primary);
  padding: 1px 10px;
  margin-top: 1px;
  display: inline-block;
  border-radius: 20px;
  min-width: 50px;
  text-align: center;
}
.user-free .userPlan {
  background: #909090;
}
/* .header-profile .user-free .profile-sm {
  border: 2px solid var(--dark-background);
}

/* .header-profile .user-premium .profile-sm {
  border: 2px solid var(--primary);
} */

/* .header-profile .user-premium .userPlan {
  color: var(--primary);
} */

.header-profile .user-free .profile-rank {
  display: none;
}

.profile-rank {
  position: absolute;
  width: 19px;
  left: -8px;
  top: 0px;
}

.navbar-toggler {
  color: var(--black);
  opacity: 1;
  border: none;
}

.navbar-toggler:focus,
.form-select:focus {
  box-shadow: none;
}

label.form-label {
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
  font-weight: 400;
  color: #F2E4FC;
}

.edit_profile {
  max-width: 1000px;
}

.navbar-brand img {
  max-width: 170px;
  height: 42px;
  object-fit: contain;
}

li.header-profile.nav-item {
  /* pointer-events: none; */
  cursor: pointer;
}
.opacity-05 {
    opacity: 0.5;
}
/* Side Drawer css  */
.sideDrawer {
  position: fixed;
  left: 0;
  top: 0px;
  background: var(--dark-background);
  width: 305px;
  height: 100dvh;
  overflow: auto;
  z-index: 9;
  /* padding: 15px; */
}

.mobile-profile .nav-link {
  color: #ebebeb;
}
.menu-icon {
  /* background: rgb(233, 236, 239); */
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 0.5rem;
  box-shadow: rgba(20, 20, 20, 0.12) 0rem 0.25rem 0.375rem -0.0625rem, rgba(20, 20, 20, 0.07) 0rem 0.125rem 0.25rem -0.0625rem;
  transition: margin 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.9rem;
}

.active-menu-text-color{
  color: var(--primary);
}

.default-color{
  color: var(--menuItemLink);
}

.sideDrawer .menu-icon img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.sideDrawer .vr-exp.dropdown-toggle.nav-link img {
  padding: 0;
}
.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin:0;
  min-height:100px;
  color: #999999;
}
.viewr-page .simple-header {
  position: absolute;
  top: 15px;
  right: 0;
  /* width: 100%; */
  z-index: 99;
}

.viewr-page ol.breadcrumb {
  display: none;
}
.sideDrawer .nav-item {
  background: var(--card-background);
  margin: 5px 0.5rem;
  border-radius: 0.5rem;
  overflow: hidden;
}
.dropdown.menu__active.nav-item .dropdown-toggle {
  background-color: #1D2041;
  box-shadow: 0px 1px 5px #0001;
  margin-bottom: 5px;
}
.sideDrawer .nav-item .nav-link {
  display: flex;
  align-items: center;
  color: var(--menuItemLink);
  opacity: 1;
  background: var(--dark-background);
  font-weight: 400;
  font-size: 16px;
  padding: 0.675rem 0.8rem 0.675rem 1rem;
}

.sideDrawer .nav-item.active {
  box-shadow: 0rem 0.05rem 0.8875rem 0rem rgba(0, 0, 0, 0.15);
  margin: 10px 0.5rem;
}

.sideDrawer .nav-item.active .nav-link {
  background-color: #1D2041;
}

.sideDrawer .dropdown-menu {
  border: none;
  padding-left: 20px;
  display: none !important;
  background: var(--card-background);
}
.sideDrawer .dropdown-menu .dropdown-item:after {
  content: "";
  width: 0;
  height: 8px;
  border-left: 8px solid;
  border-radius: 20px;
  left: 10px;
  position: absolute;
  top: 16px;
}
.sideDrawer .dropdown-menu .dropdown-item {
  cursor: pointer;
  padding: 10px;
  color: var(--menuItemLink);
  font-size: 16px;
  font-weight: 400;
  position: relative;
  padding-left: 35px;
}

.dropdown-toggle::after {
  border: none;
  background: var(--darkArrow) no-repeat;
  background-size: contain;
  width: 13px;
  height: 13px;
  text-align: center;
  background-position: center;
  margin: 0;
  position: absolute;
  right: 20px;
  transition: all ease 0.3s;
}
.sideDrawer .dropdown-toggle::after {
  background: var(--primaryArrow) no-repeat;
  background-size: contain;
  background-position: center;
  margin: -19px;
}
.dropdown.menu__active.nav-item .dropdown-toggle::after {
  transform: rotate(180deg);
}
.sideDrawer .nav-item.active .menu-icon {
  /* background: var(--primary); */
  background: #9E379C;
}
.sideDrawer .menu__active .dropdown-menu {
  background-color: #262947;
  display: block !important;
  border-radius: var(--card-some_how_light-background) !important;
}

.mobile-profile {
  /* background: #fafafa; */
}

/* Breadcrumb css  */
.breadcrumb_icon {
  transform: rotate(180deg);
  max-width: 7px;
  margin-right: 10px;
  margin-bottom: 3px;
}
.breadcrumb-item a {
  color: #a9adcd;
}
header.header .breadcrumb-item.active, .breadcrumb-item a {
  /* color: var(--black); */
}
.breadcrumb-item img {
  max-width: 14px;
  margin-top: -5px;
}
.breadcrumb-item {
  font-size: 0.875rem;
}
/* modal css  */
.modal-header,
.modal-footer {
  border: none;
}

.modal-title {
  width: 100%;
  padding: 0 25px;
}

.modal-header,
.modal-footer {
  border: none;
}

.modal-footer .btn img {
  max-width: 15px;
  max-height: 15px;
  margin-right: 10px;
  margin-top: -3px;
}

.cancelIcon {
  height: 12px;
}

.btn-close:focus {
  box-shadow: none;
}

.modal-header .btn-close {
  background: var(--close);
  background-size: contain;
  opacity: 1;
  min-width: 28px;
  padding: 0;
  height: 28px;
  position: absolute;
  right: 18px;
  top: 18px;
  z-index: 999;
  cursor: pointer;
}

.features-modal .modal-header .btn-close {
  background: var(--closelight);
  background-size: contain;
}
/* main content css  */
.main-content {
  margin-left: 300px;
  overflow-x: hidden;
  padding: 10px 25px 15px;
  position: relative;
}
.header-parent {
  position: relative;
  margin-bottom: 25px;
}
.edit_profile .btn, .change_password .btn {
  min-width: 110px;
}

.flex-1 {
  flex: 1;
}

.header-innermenu li {
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  transition: all ease 0.3s;
}

.header-innermenu li.active {
  color: var(--primary);
  /* border-radius: 0.5rem; */
  /* background-color: rgb(255, 255, 255);
  box-shadow: rgb(221, 221, 221) 0rem 0.0625rem 0.3125rem 0.0625rem; */

}


.header-innermenu li a {
  z-index: 2;
  position: relative;
  padding-left: 26px;
  padding-right: 25px;
  cursor: pointer;
}
.change_password {
  max-width: 400px;
}

.uploadBtn input {
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
  width: 300px;
  height: 43px;
  cursor: pointer;
}

.vrirual-tour-upload-logo-button input {
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
  width: 90px;
  height: 43px;
  cursor: pointer;
}

.vrirual-tour-upload-logo-button {
  position: relative;
  overflow: hidden;
  font-size: 0.9rem;
}

.uploadBtn {
  position: relative;
  overflow: hidden;
  font-size: 0.9rem;
}

.modal-dialog.medium {
  max-width: 650px;
}

/* Dashboard css  */
.no-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 160px);
  padding: 15px;
}

.innerHeader {
  padding: 15px 20px 0 20px;
}

.shared_item.dashboard_card,
.Library.dashboard_card,
.Dashboard_Counter {
  margin-left: 15px;
  margin-right: 15px;
}

.Library.dashboard_card .card-body {
  cursor: pointer;
}

.dashboard_card .card-body {
  cursor: pointer;
}

.ReactCrop__child-wrapper {
  max-width: 80vw !important;
  max-height: calc(100vh - 250px) !important;
}

.addAssets {
  overflow: hidden;
  position: fixed;
  right: 15px;
  bottom: 15px;
  transition: all ease 0.3s;
  z-index: 7;
}

.customCheck+.card-body {
  position: relative;
  z-index: 8;
}

.addAssets:hover {
  transform: scale(1.05);
}

.addAssets .btn.btn-link {
  background: transparent !important;
  padding: 0 !important;
  border: none !important;
}

.Dashboard_Counter {
  display: flex;
  align-items: center;
  padding: 30px 0px;
  border-radius: 6px;
  box-shadow: 2px 2px 5px #0003;
  max-width: 900px;
}

.Counter_item:not(:first-child) {
  border-left: 2px solid var(--primary);
}

.Counter_item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.dashboard_card .card-body {
  position: relative;
}

.dashboard_card .card {
  overflow: hidden;
  transition: all ease 0.3s;
  box-shadow: 0px 0px 10px #0003;
  border-color: #0000001f;
}

.dashboard_card .card-footer {
  transition: all ease 0.3s;
  text-transform: capitalize;
  border-top: none;
}

.assets_image {
  width: 100%;
  height: 170px;
  object-fit: cover;
}

.assets_item .card.selected .assets_image {
  height: calc(170px - 16px);
}

.dashboard_card .card-footer img[alt="Folder"] {
  margin-top: -3px;
}

.dashboard_card .card:hover {
  border-color: var(--primary);
}

.dashboard_card .card:hover .card-footer {
  background: var(--primary);
  color: #fff;
}

.dashboard_card .card:hover .card-footer img {
  filter: brightness(100);
}

.shared_item .assets_image {
  height: 150px;
}

.shared_item .col-sm-6 {
  width: 168px;
  padding-left: 8px;
  padding-right: 8px;
}

.orangeHeader {
  background: #fdd7bd;
}

.sharedFF span.customCheck {
  display: none;
}

.profile-sm-square {
  width: 50px;
  height: 50px;
  border: 1px solid #e1bba2;
  border-radius: 3px;
  object-fit: cover;
}

.shared_item .row {
  margin-left: -8px;
  margin-right: -8px;
}

.shared_item .card-footer {
  padding: 13px;
  display: flex;
  align-items: center;
}

.shared_item .slick-slider {
  padding: 0;
}

.dashboard_card .card:hover .card-footer .light_icon {
  display: inline-block;
}

.dashboard_card .card .card-footer .light_icon {
  display: none;
}

.dashboard_card .card:hover .card-footer .dark_icon {
  display: none;
}

.modal-content{
  background: var(--card-background);
}

.drop-modal .modal-content button.modalClose {
  position: absolute;
  padding: 0 !important;
  background: transparent;
  border: none;
  top: -20px;
  right: -25px;
}

.drop-modal .modal-content button.modalClose:active {
  background: transparent;
}

.small-thumb,
img.dzu-previewImage {
  width: 60px;
  height: 45px;
  object-fit: cover;
  border-radius: 4px;
}

.uploaded-items,
.dzu-previewContainer {
  border: 1px solid #ccc !important;
  border-radius: 6px;
  margin-top: 16px;
  padding: 10px !important;
}

/* .Drop_Area {
  min-height: 150px;
  text-align: center;
  line-height: 150px;
  border: 1px dashed #a5a5a5;
  border-radius: 6px;
} */

.drop-modal .modal-content button img {
  max-width: 30px;
}

button.play {
  display: block;
  width: 14px;
  height: 14px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 10px solid #2c3e50;
  margin: 16px auto 0px auto;
  position: relative;
  z-index: 1;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  border-right: none;
  background: transparent;
  float: right;
}

.progress-bar {
  background: var(--primary) !important;
}

button.play.finished {
  border: none;
  transform: rotate(45deg);
  margin-top: 22px;
}

.play.finished:after {
  content: "";
  width: 14px;
  height: 2px;
  position: absolute;
  left: -9px;
  top: 6px;
  background: #000;
}

.play.finished:before {
  content: "";
  width: 2px;
  height: 14px;
  position: absolute;
  left: -3px;
  top: 0px;
  background: #000;
}

.play.active {
  border-color: transparent;
}

.play.active:after {
  content: "";
  opacity: 1;
  width: 9px;
  height: 14px;
  background: #2c3e50;
  position: absolute;
  left: -10px;
  top: -6px;
  border-left: 3px solid #2c3e50;
  box-shadow: inset 3px 0 0 0 #f9f9f9;
}

.upload_item .progress {
  width: calc(100% - 40px);
  float: left;
  margin-top: 21px;
}

body:not(.modal-open) {
  overflow: auto !important;
}

body.modal-open {
  overflow: hidden !important;
}

.userName {
  text-transform: capitalize;
}

.btn.btn-pills {
  border-radius: 25px;
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.userInfo {
  font-size: 0.9rem;
}
.userInfo h5 {
  text-transform: capitalize;
  font-size: 1rem;
  margin-top: 5px;
}
.dzu-dropzone {
  width: 100% !important;
  border: none !important;
  border-radius: 6px;
  height: 230px !important;
}
.inputUploader {
  width: 100%;
  border: 1px dashed #a5a5a5;
  padding: 25px;
  box-sizing: border-box;
  height: 100%;
  border-radius: 4px;
}
.inputUploaderLoader {
  border: 1px solid #ccc;
    margin-top: 15px;
    padding: 15px 5px 15px 15px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cancel-upload {
  max-width: 20px;
}
.inputUploaderLoader .progress-bar {
  border-radius: 10px;
}

.inputUploaderLoader .progress {
  height: 0.4rem;
  background: #ccd3ff;
}
.dzu-inputLabel {
  color: var(--menuItemLink) !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  width:100% !important;
  display: inline-block !important;
  text-align: center;
}
.dzu-inputLabel img {
  max-width: 200px;
  margin-bottom: 25px;
}
.dzu-inputLabel span {
  color: #0d6efd;
}

progress {
  background: var(--primary);
  height: 5px;
  border-radius: 6px !important;
  overflow: hidden;
}

progress {
  color: #e9ecef;
  margin-right: 7px;
}

progress::-moz-progress-bar {
  background: #e9ecef;
}

.dzu-previewStatusContainer {
  margin-right: 5px;
}

progress::-webkit-progress-value {
  background: var(--primary);
}

progress::-webkit-progress-bar {
  background: #e9ecef;
}

span.dzu-previewFileName {
  word-break: break-word;
  padding-right: 10px;
}

/* assets management css  */
.assets_item .card {
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.assets_item .card:hover {
  border-color: var(--primary);
}

/* .assets_item .card:after {
  content: "";
  width: 16px;
  height: 16px;
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 9;
  border-radius: 10px;
  border: 1px solid #fff;
} */
.customCheck {
  position: absolute;
  right: 5px;
  top: 5px;
  background: transparent;
  z-index: 9;
  width: 18px;
  height: 18px;
  border-radius: 20px;
  border: 1px solid #fff;
  cursor: pointer;
}

.assets_item .card.selected .customCheck {
  background: var(--primary);
  border-color: var(--primary);
  right: -4px;
  top: -4px;
}

.assets_item .card.selected span:before {
  content: "";
  width: 10px;
  height: 6px;
  position: absolute;
  top: 4px;
  right: 3px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  z-index: 10;
  transform: rotate(-40deg);
}

.assets_item .card.selected {
  border: 8px solid #fdd7bd;
  overflow: initial;
}

.innerHeader.withBorder {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 10px;
}

.btn-sm img {
  max-width: 13px;
  margin-top: -2px;
}

nav.mb-0[aria-label="breadcrumb"] ol.breadcrumb {
  margin-bottom: 0;
}

.btn img {
  margin-top: -2px;
}

.btn.btn-link:not(.btn-sm),
.btn.btn-link:focus,
.btn.btn-link:hover {
  padding: 0 !important;
  border: none !important;
  text-decoration: none;
  color: #000;
  background: transparent;
}

.assets_item .card .card-body {
  display: flex;
}

.modal-dialog.drop-modal.imageModal img {
  max-height: 80vh;
  object-fit: contain;
}

.modal-dialog.drop-modal.imageModal figure {
  display: flex;
  align-items: center;
}

.imageModal li[role="button"] img {
  display: none;
}

.imageModal li[role="button"] {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  border: 1px solid #fff;
  min-width: 10px;
  margin: 3px;
  opacity: 1;
}


.imageModal li[role="button"]._19gvi {
  background: #fff;
}

.imageModal span.__JnHV {
  display: none;
}

.imageModal ._3lwW_._lhmht {
  display: none;
}

.imageModal ul._-LJ2W {
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  flex-wrap: wrap;
}

.imageModal span.__JnHV {
  display: none;
}

.imageModal ._3lwW_._lhmht {
  display: none;
}

.imageModal ._lfOsC._1Pekn svg._3WRGR {
  opacity: 0;
}

.imageModal ._3lwW_._1cqA3 ._lfOsC._1Pekn:after {
  transform: rotate(135deg);
  right: 7px;
}

.imageModal ._ZTBlf {
  color: #fff;
  opacity: 1;
}

.imageModal ._ZTBlf {
  opacity: 1;
}

.imageModal ._3lwW_._1oKnM {
  bottom: 5px;
  top: auto;
  right: 5px;
}

.imageModal ._2c50p {
  position: absolute;
  bottom: 0;
  width: calc(100% - 40px);
}

.imageModal ._2qwzr {
  position: static;
}

.imageModal ._3WRGR {
  width: 24px;
}

.imageModal ._lfOsC._1Pekn:after {
  content: "";
  width: 20px;
  height: 20px;
  border-right: 4px solid;
  border-bottom: 4px solid;
  transform: rotate(-45deg);
  right: 15px;
  position: relative;
}

.assets_item.am_audios .card img {
  height: 60px;
  margin: 3.2em auto;
}

.assets_item.am_audios .card.selected img {
  margin: 2.7em auto;
}

.DashboardNoItems {
  text-align: center;
  background: #eee;
  padding: 5rem 15px;
  margin-top: 15px;
}

.share_people .btn.btn-primary {
  min-width: 110px;
}

.btn.btn-cancel {
  background: #fff;
  color: #6c757d;
  border-color: #6c757d;
}

.btn.btn-me-cancel {
  background: #fff;
  color: #6c757d;
  border-color: #6c757d;
  min-width: 160px;
  margin-bottom: 15px;
}


/* .assets_item .card.selected img {
  height: calc(168px - 10px);
} */
.videoModal .modal-body,
.imageModal .modal-body {
  padding: 0;
  position: relative;
  overflow: hidden;
}


.imageModal .modal-body>div {
  width: 100% !important;
  /* height: auto !important; */
  display: flex;
  border-radius: 7px;
  overflow: hidden;
}

.videoModal .modal-body>div {
  width: 100% !important;
  height: 500px !important;
  display: flex;
  border-radius: 7px;
  overflow: hidden;
}

.modal-dialog.videoModal,
.modal-dialog.imageModal {
  max-width: 800px;
  width: auto;
}

.videoCard .card-body>div {
  height: 170px !important;
  width: 100% !important;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
}

.videoCard .card.selected .card-body>div {
  height: 158px !important;
}

.videoCard .assets_item .card .card-body {
  align-items: center;
  justify-content: center;
  /* background: #000; */
}

.audio-modal .modal-body {
  padding-left: 0;
  padding-right: 0;
}

.rhap_container {
  box-shadow: none !important;
}

.audio-modal .modal-body {
  padding-left: 0;
  padding-right: 0;
}

.rhap_progress-indicator {
  background: var(--primary) !important;
  height: 15px !important;
  width: 15px !important;
  top: -4px !important;
}

.rhap_volume-indicator {
  background: var(--primary) !important;
  top: -3px !important;
}

.rhap_volume-bar {
  height: 6px;
}

.rhap_progress-filled {
  background: var(--primary) !important;
  border-radius: 6px !important;
}

.rhap_progress-section {
  padding: 10px 0 20px !important;
}

.rhap_progress-bar {
  height: 8px !important;
  border-radius: 6px !important;
}

img.play_image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.shared_item .slick-track {
  margin-left: 0;
}

.imageModal li[role=button] {
  display: none;
}

.orangeHeader img {
  cursor: pointer;
}

.card-footer {
  padding: 13px;
}

.userCard img {
  max-height: 177px;
  object-fit: cover;
}

.userCard,
.VR_rooms,
.pin-management,
.inviteCont {
  /* margin: 0 15px; */
}

.VR_rooms .card {
  border: none;
  background: transparent;
  cursor: pointer;
}

.VR_rooms .card-footer {
  border: none !important;
  background: transparent;
  padding-left: 0px;
  padding-right: 0px;
}

.VR_rooms .card-body {
  box-shadow: 0px 0px 10px #0003;
  border-radius: 6px;
  background: #fff;
  padding: 45px 15px;
  position: relative;
}

img.vr-icon {
  position: absolute;
  right: 8px;
  top: 8px;
}

button.btn.btn-white:hover {
  color: #fff;
}

.nav-tabs a {
  background: #e8e8e8 !important;
  border-radius: 0 !important;
  margin-right: 7px;
  margin-bottom: 7px !important;
  min-width: 150px;
  text-align: center;
  color: #000;
  border-bottom: 5px solid #e8e8e8 !important;
  border-top: 5px solid #e8e8e8 !important;
  cursor: pointer;
}

.nav-tabs a.active {
  border-bottom-color: var(--primary) !important;
  color: #000 !important;
}

.tab-content {
  background: var(--card-some_how_light-background);
  padding: 15px;
  padding-bottom: 0;
}

.nav-tabs {
  border: none;
}

.nav-tabs .nav-link:hover {
  color: #000 !important;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.modal-dialog .tab-content .tab-pane {
  max-height: calc(100vh - 290px);
  overflow-x: auto;
  min-height: 170px;
  width: 100%;
  padding: 0 15px;
}

.modal-xl .modal-body {
  padding: 25px;
}

.imageHeader {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.imageHeader h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 2rem;
  width: 100%;
  text-align: center;
}

.asset_filter {
  float: right;
}

.form-select.select_secondary {
  background-color: #e8e8e8;
  border: none;
  border-radius: 3px;
  padding-right: 40px !important;
}

.asset_filter select {
  width: auto;
}

.modal .no-content img {
  max-width: 250px;
}

.modal .no-content {
  min-height: auto;
}

.Toastify__progress-bar.Toastify__progress-bar--animated.Toastify__progress-bar-theme--light.Toastify__progress-bar--error {
  background: var(--primary);
}

.Toastify__progress-bar.Toastify__progress-bar--animated.Toastify__progress-bar-theme--light.Toastify__progress-bar--default {
  background: var(--primary);
}

.pm-card {
  padding: 5px;
  border: none;
  box-shadow: 0px 0px 10px -3px #0005;
  border-radius: 10px;
}

.viewPin {
  margin-left: 26px;
  max-width: 18px;
  filter: brightness(0.5);
  margin-top: -3px;
  cursor: pointer;
}

.pin.hide {
  font-size: 50px;
  line-height: 0px;
  display: inline-block;
  letter-spacing: -4.8px;
  height: 19px;
  position: relative;
  top: -5px;
}

.modal-pinGeneration h5 {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.modal-pinGeneration button {
  width: calc(50% - 20px);
  max-width: 150px;
}

.truncateEmail {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.assets_item .videoCard .card .card-body {
  align-items: center;
  justify-content: center;
  background: #2e2e2e;
}

.videoModal .modal-body video {
  max-height: calc(100vh - 60px);
}

.padlock {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #0005;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.3s;
  transform: scale(0);
  border-radius: 6px;
}

.VR_rooms .card-body .padlock img {
  width: 27px;
}

.VR_rooms .card:hover .card-body .padlock {
  transform: scale(1);
}

.inner_lists {
  max-height: calc(100vh - 164px);
  overflow-y: auto;
}

.invite-screen {
  background: #fff;
  border-radius: 11px;
  padding: 25px 30px;
  max-width: 700px;
  margin: 0 auto;
  box-shadow: 2px 2px 10px -3px #0004;
}

.invite-screen label {
  margin-bottom: 5px;
  font-size: 15px;
}

.invite-screen h6 {
  line-height: 24px;
}

.invite-screen label {
  margin-bottom: 5px;
  font-size: 15px;
}

.ql-toolbar.ql-snow {
  display: none;
}

.invite-screen .ql-container.ql-snow {
  border: 1px solid #ced4da !important;
  border-radius: 6px;
  min-height: 150px;
}

.assets_item .card-footer {
  background: #fff;
  border-top: none;
}

.assets_item .card-footer p {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.bg-primary {
  background: var(--primary) !important;
}

/* styline  */
.align-end {
  text-align: end;
}

.align-end img.margin {
  padding-right: 10px;
  margin-left: 10px;
}

.virtual-tour-and-img .card-body img {
  height: 220px;
  max-height: 300px;
}

.cancel-input-boxes-container {
  padding: 0;
  margin-top: 7px;
}

.cancel-input-boxes-container img {
  /* height: 24px; */
  cursor: pointer;
}

.add-input-boxes-container {
  padding-bottom: 10px;
}

.add-input-boxes-container_lable {
  padding-bottom: 10px;
}

.invite-contributors-add-assets {
  margin-right: 20px !important;
}

.add-input-boxes-container-upper-div {
  background: #fff;
  border-radius: 11px;
  padding: 25px 30px;
  max-width: 700px;
  margin: 0 auto;
  box-shadow: 2px 2px 10px -3px #0004;
}

.add-new-contributor-button {
  border: 0px;
  background: white;
}

.cursor-none {
  cursor: not-allowed;
  pointer-events: all !important;
}

.quill {
  background-color: #ececec;
}

.second-label-input-and-email {
  position: relative;
  left: 208px;
}

.modal-wrapper-thankyou {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  width: 100%;
  margin: 50px;
}

.video-optimized-info-text {
  font-size: 19px;
}

.video-optimized-info-text-footer {
  position: relative;
  top: 17px;
}

.files-upload-processing {
  height: 100px;
}


.create-virtul-tour-nav a {
  border-top: 0px !important;
  border-bottom: 0px !important;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  /* width: 350px; */
  margin-bottom: 0px !important;
  /* height: 55px;
    padding-top: 16px; */
}

.create-virtul-tour-nav a.active {
  border-bottom-color: var(--primary) !important;
  color: #fff !important;
  background: var(--primary) !important;
}

/* .create-virtul-tour-nav-itme {
  border-bottom-color: var(--primary) !important;
  border-bottom: 5px solid;
} */

span.custom-radio {
  display: inline-block;
  margin-right: 15px;
}

.custom-radio input {
  position: relative;
  top: 2px;
}

.custom-radio label {
  padding-left: 5px;
}

.browse-image {
  position: relative;
  border: 1px dashed #ced4da;
  border-radius: 6px;
}

.browse-content {
  text-align: center;
  padding: 3rem 10px;
}

.browse-image input {
  position: absolute;
  left: -100px;
  height: 100%;
  width: calc(100% + 100px);
  cursor: pointer;
  z-index: 2;
  opacity: 0;
}
.virtual-tour-radio-button {
  width: 18px;
  height: 18px;
  accent-color: #ff4e00;
}

.create-virtul-tour-nav .active.nav-link:hover {
  color: #fff !important;
}

.create-virtul-tour-nav a.nav-link {
  font-size: 14px;
  padding: 0.5rem 1rem;
}

.create-virtul-tour-nav li:last-child a.nav-link {
  margin-right: 0;
}

.virtualMdl .tab-content {
  padding: 15px 0px;
}

.virtualMdl .tab-content .tab-pane {
  padding: 0px;
  min-height: 116px;
}

.virtualMdl .modal-body {
  padding: 25px;
}

.virtualMdl .form-control {
  background: var(--dark-background);
}

.x-hidden {
  overflow-x: hidden;
}

.tour_image {
  width: 100%;
  max-height: 200px;
  border-radius: 4px;
  object-fit: cover;
  box-shadow: 0px 3px 10px 0 #0003;
}

.virtualMdl label {
  font-size: 14px;
}


/* panorama desing start */


.panorama {
  width: 1200px;
  height: 700px;
}

.custom-hotspot {
  background-image: url('./../Images/icons/Lable.png');
  width: 45px;
  height: 45px;
  background-size: contain;
  background-repeat: no-repeat;
}

.hotspot-location {
  background-image: url('./../Images/icons/Lable.png');
  width: 45px;
  height: 45px;
  background-size: contain;
  background-repeat: no-repeat;
}

.hotspot-image {
  background-image: url('./../Images/icons/Image.png');
  width: 45px;
  height: 45px;
  background-size: contain;
  background-repeat: no-repeat;
}

.hotspot-video {
  background-image: url('./../Images/icons/Video_three_sixty.png');
  width: 45px;
  height: 45px;
  background-size: contain;
  background-repeat: no-repeat;
}

.hotspot-audio {
  background-image: url('./../Images/icons/hotspotAudio.png');
  width: 45px;
  height: 45px;
  background-size: contain;
  background-repeat: no-repeat;
}

.hotspot-url {
  background-image: url('./../Images/icons/URL.png');
  width: 45px;
  height: 45px;
  background-size: contain;
  background-repeat: no-repeat;
}

.add-hot-spot {
  background-image: url('./../Images/icons/location.png');
  width: 45px;
  height: 45px;
  background-size: contain;
  background-repeat: no-repeat;
}

div.custom-tooltip span {
  visibility: hidden;
  position: absolute;
  border-radius: 3px;
  background-color: #fff;
  color: #000;
  text-align: center;
  max-width: 200px;
  padding: 5px 10px;
  margin-left: -220px;
  cursor: default;
}

div.custom-tooltip:hover span {
  visibility: visible;
}

div.permanent-tooltip-visible span {
  visibility: visible;
}

div.permanent-tooltip-visible:hover span:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
  bottom: -20px;
  left: -10px;
  margin: 0 50%;
}

div.custom-tooltip:hover span:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
  bottom: -20px;
  left: -10px;
  margin: 0 50%;
}


/* showing hot-spot */

.showHotspot {
  visibility: hidden;
  width: 250px;
  height: 182px;
  background: red;
  text-align: center;
  position: relative;
  left: 27px;
  top: 26px;
}


div.custom-tooltip:hover div {
  visibility: visible;
  position: relative;
  left: 10px;
  top: 10px;
}

/* adding hot-spot */
.myDiv {
  width: 350px;
  background: #393a39;
  text-align: center;
  position: relative;
  left: 55px;
  top: 0px;
  padding: 15px 25px 25px;
  font-size: 12px;
  border-radius: 6px;
}

.myDiv:after {
  content: "";
  width: 15px;
  height: 15px;
  background: #393a39;
  position: absolute;
  top: 13px;
  left: -6px;
  transform: rotate(45deg);
}

.myDiv label {
  width: 100%;
  text-align: left;
  color: #fff;
  font-size: 12px;
  margin-bottom: 3px;
}

.pnlm-container .myDiv * {
  box-sizing: border-box;
}
/* To hide background cubes*/
.pnlm-load-box > div, .pnlm-load-box p {
    visibility: hidden !important;
}
.pnlm-load-box {
    width: 100%;
    height: 100%;
    left: 0;
    margin: 0;
    top: 0;
}
.pnlm-load-box .loader {
    visibility: visible !important;
}
.pnlm-container {
  background: #f9fafa !important;
}

.myDiv input {
  background: #fff2;
  border: transparent;
  padding: 0.5rem 1rem;
  font-size: 12px;
  color: #FFF;
}

.myDiv input:focus {
  background: #fff2;
  color: #FFF;
}


.rdo-btn span {
  position: relative;
  cursor: pointer;
}

.rdo-btn input {
  opacity: 0;
}

.rdo-btn {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.myDiv .form-group {
  margin-bottom: 15px;
}

.rdo-btn span label:after {
  content: "";
  border: 2px solid #fff;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  border-radius: 9px;
  top: 1px;
}

.btn.pannelium-tag-button:not(.btn-sm) {
  min-width: 85px;
  padding: 0.3rem 1.2rem !important;
  margin-top: 10px;
  font-size: 13px;
}

.rdo-btn span input:checked+label:after {
  border: 2px solid #ff4e00;
}

.rdo-btn span input:checked+label:before {
  content: "";
    width: 10px;
    height: 10px;
    background: #ff4e00;
    position: absolute;
    left: 3px;
    top: 4px;
    border-radius: 10px;
}

.rdo-btn label {
  width: auto;
  padding-left: 5px;
  cursor: pointer;
}

.browse-option input {
  width: 450px;
  position: relative;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
  left: -100px;
  height: 34px;
}

.browse-option {
  background: #fff2;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.browse-option span {
  position: absolute;
  left: 0;
  padding: 0.5rem 1rem;
  color: #fff;
  text-align: center;
  width: 100%;
  top: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 21px;
}

.panorama-wrap {
  position: relative;
  width: 100%;
  transition: all ease 0.3s;
  z-index: 3;
}

.virtual-tour-wrap {
  overflow: hidden;
  position: relative;
}
.tour-drawer-inner {
    width: 310px;
    height: 100%;
    background: var(--card-background);
    /* background: #fff; */
    transition: all ease 0.3s;
    padding: 5px 0;
    max-height: calc(100vh - 0px);
    overflow: auto;
}

.virtual-tour-drawer.actDrawer {
    right: 0;
}
.virtual-tour-drawer.actDrawer {
      right: 0;
}

.tagitem {
  display: flex;
  align-items: center;
  padding: 7px 20px;
  width: 100%;
  cursor: pointer;
}

.tagitem img {
  width: 17px;
  height: 17px;
  object-fit: contain;
}

.tagitem p {
    width: calc(100% - 46px);
    margin: 0px;
    line-height: 17px;
    padding-right: 5px;
    font-size: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.tagitem img.tagImage {
  text-align: left;
  margin-right: 12px;
}

.virtual-tour-drawer .accordion-button {
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 7px;
}

.aordion-button:focus {
  box-shadow: none !important;
}

.virtual-tour-drawer .accordion-button:after {
  width: 14px;
  height: 14px;
  background-size: contain;
  background-image: var(--bs-accordion-btn-icon);
  /* margin-right: 10px; */
}

.virtual-tour-drawer .accordion-item {
  color: var(--light);
  background: var(--card-background);
  border: none;
}

/* panorama desing end */
.addtag img.add {
  transform: rotate(45deg);
  margin-right: 4px;
  max-width: 10px;
}

.addtag img.cancel {
  max-width: 14px;
  margin-right: 5px;
}

.collapseBtn {
    position: absolute;
    /* top: 20px; */
    top: 50%;
    left: -60px;
    z-index: 2;
}

.CollapseBtn {
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 2;
}

.backBtn .btn.btn-round {
  background: #0008;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 0 !important;
  border-radius: 100%;
}

.virtual-tour-drawer {
  position: absolute;
  top: 0;
  right: -310px;
  height: 100%;
  transition: all ease 0.3s;
  z-index: 999;
}

.tagitem:hover,
.tagitem.active {
  background:  var(--primary);;
  color: #fff;
}

.tagitem:hover img,
.tagitem.active img {
  filter: brightness(0) invert(1);
}

.tagitem:hover .tagImage,
.tagitem.active .tagImage {
  filter: none !important;
}
.sideDrawer .nav-item.active .menu-icon img {
  filter: brightness(0) invert(1);
}
.h-100{
  height: 100%;
}
.backBtn {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 2;
}

.virtual-tour-drawer .accordion-body {
  padding: 0;
}

.accordion-button:not(.collapsed),
.accordion-button:hover,
.accordion-button:active,
.accordion-button:focus {
  background: transparent;
  border: none !important;
  box-shadow: none;
  color: var(--primary);
}

.no-cnt {
  font-style: italic;
  color: #bbb;
  font-size: 15px;
  margin: 0;
  padding: 0 20px;
}
.myDiv .no-cnt {
    height: 134px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-container,
.video-container,
.audio-container {
  width: 100%;
  background: #161616;
  padding: 7px 10px;
  padding-right: 5px;
  border-radius: 6px;
}

.image-container>div,
.video-container>div,
.audio-container>div {
  width: 100%;
  height: 145px;
  padding-right: 7px;
  overflow-y: auto;
}

.image-container .assets_image,
.video-container .assets_image,
.audio-container .assets_image {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  position: relative;
  z-index: 8;
}

.image-container span,
.video-container span,
.audio-container span {
  border-radius: 6px;
  overflow: hidden;
  margin: 4px;
  width: calc(33.33% - 8px);
  float: left;
  height: 64px;
  cursor: pointer;
  position: relative;
}

span.class_second img {
  border: 3px solid #ffae8a;
}

span.class_second:after {
  content: "";
  width: 7px;
  height: 4px;
  position: absolute;
  top: 5px;
  right: 5px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  z-index: 10;
  transform: rotate(-40deg);
}

.image-container span:before,
.video-container span:before,
.audio-container span:before {
  content: "";
  border: 1px solid #ccc;
  width: 12px;
  height: 12px;
  border-radius: 20px;
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 9;
  background: #0003;
}

span.class_second:before {
  background: #ff4e00;
  border-color: #ff4e00;
}

div#audio-result-container span {
  height: auto;
}

div#audio-result-container span img {
  height: 53px;
}

div#audio-result-container span label {
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  font-size: 11px;
}

div#video-result-container span label.vid_icon {
  background-image: url('./../Images/icons/play.svg');
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
}
.addtag >div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.addtag button, .addtag span.tagButtonInfo {
    bottom: 25px;
    left: 0;
    position: absolute;
    text-align: center;
    transition: all .3s ease;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
}
 .addtag span.tagButtonInfo {
    background: #0009;
    color: #fff;
    padding: 15.5px 20px;
    border-radius: 6px;
    font-size: 15.5px;
    font-weight: 300;
    text-align: center;
    bottom: 90px;
    min-width: 387px;
}
.myDiv .btn.btn-secondary {
    background: #242424;
}
.myDiv .btn {
    margin: 0 5px;
}
.pnlm-render-container .custom-tooltip span p {
    margin: 0;
}

.pnlm-render-container .custom-tooltip span {
    background: #0009;
    color: #fff;
    font-size: 13px;
    padding: 10px 15px;
}

.pnlm-render-container .custom-tooltip:hover span:after {
    border-color: #0009 transparent transparent transparent;
}

/*
 *  scroll-style
*/

.scroll-style::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 5px;
  background-color: #eee3;
}

.scroll-style::-webkit-scrollbar
{
  width: 7px;
  background-color: #0003;
}

.scroll-style::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #ff4e00;
}
.collapseBtn img {
    cursor: pointer;
}
.pnlm-hotspot-base {
    z-index: 2;
}

.prevent-context-view {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}
.drawer-wrap {
  max-height: calc(100vh - 97px);
  overflow-y: auto;
  padding-bottom: 15px;
}
.white-box {
  box-shadow: rgb(0 0 0 / 12%) 0rem 0.25rem 1.6875rem 0rem;
  padding: 22px;
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: var(--card-background);
}

.white-box-gradiant{
  background-image: linear-gradient(to right, #780B78, #AA45A7);
  color: #fff;
}

.color-white{
  color: #fff !important;
}

.visibility-hidden {
  visibility: hidden;
}
.border-box{
  /* border: 1px solid var(--card-some_how_light-background);; */
  /* background: #fafafa; */
  padding: 22px;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
}
.btn.btn-sm.btn-pills {
  border-radius: 25px;
  padding-left:  27px !important;
  padding-right:  27px !important;
}

.profile-cont .form-control,.profile-cont .form-select {
  font-size: 0.9rem;
}
.edit-profile {
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}
.outside-close-modal .btn-close {
  background: var(--close);
  background-size: contain;
  opacity: 1;
  min-width: 32px;
  padding: 0;
  height: 32px;
  position: absolute;
  right: -25px;
  top: -25px;
}
.btn.btn-outline-primary:hover img {
  filter: brightness(0) invert(1);
}
.form-control:focus {
  border-color: #ced4da;
  background-color: var(--dark-background);
  color: var(--light);
}
.form-control, .form-select {
  font-size: 0.9rem;
  background-color: var(--dark-background);
  color: var(--light);
}
.curved-image-wrap:before, .curved-image-wrap:after {
  content:"";
  background: var(--imageShadow);
  background-repeat: no-repeat;
  background-size: contain;
  height: 50px;
  width: calc(100% - 20px);
  position: absolute;
  top: 0px;
  left: 10px;
  z-index: 3;
}

.curved-image-wrap_tour:before, .curved-image-wrap_tour:after {
  content:"";
  background: var(--imageShadow);
  background-repeat: no-repeat;
  background-size: contain;
  height: 50%;
  width: calc(100% - 0px);
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 3;
  background-color: rgba(199, 0, 227, 0.06);
}
.curved-image-wrap_tour:after {
  bottom: -0.4px;
  top: auto;
  transform: rotate(180deg);
}
.curved-image-wrap_tour {
  position: relative;
  border-radius: 6px;
  width: 100%;
  overflow: hidden;
}
.curved-image-wrap_tour img {
  position: relative;
  z-index: 2;
}
.Three-sixty-icon-overlay{
  position: relative;
  height: 0px;
  z-index: 5;
  top: 75px;
}



.creative-list li:before {
  content:"";
  background: var(--arrowImage);
  background-repeat: no-repeat;
  background-size: contain;
  height: 12px;
  width: 12px;
  position: absolute;
  top: 3.5px;
  left: 0;
  z-index: 3;
}
.creative-list {
  padding: 0;
  list-style: none;
}

.creative-list li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
}
.curved-image-wrap:after {
  bottom: -0.4px;
  top: auto;
  transform: rotate(180deg);
}
.more-btn img {
  max-height: 18px;
  object-fit: contain;
}
.virtual-tour-and-img .card-footer {
  border-top: none;
  background: transparent;
}
.curved-image-wrap {
  position: relative;
  border-radius: 6px;
  width: 100%;
  overflow: hidden;
}
.virtual-tour-and-img .more-btn.btn.btn-link {
  padding: 0px 10px 0  !important;
}
.virtual-tour-and-img .card {
    /* background: #fff; */
    background: var(--card-background);
    border: none;
    box-shadow: 0px 0px 10px -5px #0005;
    /* border: 2px solid #fff; */
    border: 2px solid var(--card-some_how_light-background);
    transition: all ease 0.3s;
}
.virtual-tour-and-img .card:hover {
  border-color: var(--primary);
}
.dropdown {
  position: relative;
}

.virtual-tour-and-img .dropdown-menu {
  border: none;
  box-shadow: 0px 0px 5px #0003;
  padding: 0;
  overflow: hidden;
  /* right: 0 !important;
  left: auto !important;
  transform: none !important;
  top: 26px !important; */
}
.btn-primary img {
  filter: brightness(100);
}
.virtual-tour-and-img  .dropdown-menu button.dropdown-item {
  display: flex;
  align-items: center;
  padding: 0.7rem 1rem;
}
.share-field input {
  width: 100%;
  padding: 10px;
  border-radius: 24px;
  border: 2px solid #eee;
  padding-right: 5.3rem;
}

.share-field span {
  background: var(--primary);
  color: #fff;
  padding: 7px 20px;
  border-radius: 20px;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}
.share-btn button {
  margin: 0 5px;
}
.primary-box.active {
  border-color: var(--primary);
}

.primary-box {
  border: 2px solid #e7e7e7;
  border-radius: 10px;
  padding: 12px;
  height: 100%;
}
.share-btn button svg {
  width: 40px;
}
.share-field {
  position: relative;
}
.virtual-tour-and-img  .dropdown-menu button.dropdown-item:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.virtual-tour-and-img .more-btn.btn.btn-link:after {
  display: none;
}
.curved-image-wrap img {
  position: relative;
  z-index: 2;
}
.dropdown-item:hover, .dropdown-item:focus, .dropdown-item:active {
  color: var(--bs-dropdown-link-hover-color) !important;
  background-color: var(--bs-dropdown-link-hover-bg) !important;
}
.sideDrawer .dropdown-menu .dropdown-item:hover,
.sideDrawer .dropdown-menu .dropdown-item:active,
.sideDrawer .dropdown-menu .dropdown-item:focus {
  background: transparent !important;
  color: var(--menuItemLink) !important;
}

.sideDrawer .dropdown-menu .dropdown-item.active {
  background: transparent !important;
  color: var(--primary) !important;
}
/* .virtual-tour-and-img .col-md-6:nth-last-child(3) .dropdown-menu, 
.virtual-tour-and-img .col-md-6:nth-last-child(2) .dropdown-menu, 
.virtual-tour-and-img .col-md-6:last-child .dropdown-menu {
  top: auto !important;
  bottom: 25px !important;
} */
.menu-btn {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 15px;
  z-index: 99;
  transform: translateY(-50%);
}

.menu-btn img {
  max-width: 45px;
}
.tour-menu-list {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  padding: 20px;
  height: 100dvh;
  transition: all ease 0.3s;
  transform: translateX(-100%);
  width: 350px;
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(9,9,121,0) 100%);
}
.tour-menu-list.open {
  transform: translateX(-00%);
}
img.img-fluid.menuClose {
  position: absolute;
  left: 15px;
}

.tour-item {
  padding: 10px;
  position: relative;
  color: #fff;
  cursor: pointer;
  text-transform: capitalize;
}

.tour-lists {
  width: 100%;
  text-align: center;
  padding-left: 40px;
  overflow: auto;
  /* height: calc(100dvh - 40px); */
  max-height: 85%;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  /* height: 85%; */
  /* display: flex;
  justify-content: center;
  flex-direction: column; */
}

.tour-lists::-webkit-scrollbar {
  display: none;
}

.tour-item:hover, .tour-item.active {
 background:var(--listbg);
 background-size: cover;
 background-repeat: no-repeat;
}

.tour-item:before{
  content:"";
  background: var(--border);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 50%;
  z-index: 3;
  transform: translateX(-50%)

}
.tour-item:last-child:before {
  opacity: 0;
}
.text-disabled {
  color: #4b4b4b;
}
.text-xxl {
  font-size: 2rem;
  font-weight: 600;
}
.small-tour {
  width: 35px;
  height: 35px;
  border-radius: 4px;
  margin-right: 15px;
  /* background: #e5e9fc; */
  padding: 7px;
  object-fit: contain;
}
.dashboard-card span img {
  width: 45px;
  height: 45px;
  object-fit: contain;
  object-position: center;
}
.views-lists {
  max-height: 280px;
  overflow: auto;
  height:100%;
}
.circle-chart {
  height: 100%;
}
.circle-chart .chart-container canvas {
  margin: 0 auto;
}
.circle-chart .chart-container {
    margin: 0 auto;
    max-height: 220px;
}

.analytics-circle-chart .chart-container {
  margin: 0 auto;
  max-height: 320px;
}

.analytics-circle-chart {
  background: var(--card-background);
}
/* width */

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}
.custom-scrollbar:hover::-webkit-scrollbar {
  width: 6px;
}
/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #c8c8c8; 
  border-radius: 10px;
}
 
/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #ccc5; 
  border-radius: 10px;
}
.custom-scrollbar:hover::-webkit-scrollbar-thumb {
  background: #ccc; 
  border-radius: 10px;
}
/* Handle on hover */
/* .custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: grey; 
} */
.dashboard-card span {
    /* background: #e5e9fc; */
    padding: 20px;
    border-radius: 10px;
    margin-right: 25px;
}
.tour-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 10px 20px;
}
.addAssets img {
  max-width: 30px;
  object-fit: contain;
}
.dashboard-line-chart canvas {
  max-height: 300px;
  min-height: 200px;
}
.no-content img {
  max-width: 400px;
}
.share-btn img {
  max-width: 13px;
}
.inner-wrap {
  padding: 0 35px;
}
.tour-image-name {
  position: absolute;
  left: 50%;
  top: 20px;
  z-index: 999;
  background: #242424;
  color: #fff;
  padding: 5px 12px;
  transform: translateX(-50%);
  border-radius: 6px;
}

.image-card-title .dropdown {
  position: absolute;
  right: 0;
  top: 0;
}

.image-card-title {
  position: relative;
  margin: 5px 0;
  min-height: 24px;
}
 
span.delete-modal {
  display: inline-block;
  width: 77px;
  height: 77px;
  border-radius: 100%;
  top: -40px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(0deg, #000 46%, #000 65%, #000 50%);
}
span.delete-modal img {
  width: 77px;
  height: 77px;
  object-fit: contain;
  padding: 20px;
  position: relative;
  z-index: 9;
  object-position: center;
}
span.delete-modal:after {
  background: #fff;
  width: 67px;
  height: 67px;
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  border-radius: 100%;
}
.inner-close .modal-header .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
  opacity:0.5
}
.image-card-title p {padding-left: 24px;padding-right: 24px;}
.x-small {
  font-size: 0.75rem;
}
.link-text {
  color: #029cff !important;
}
.more-icon img {
  padding: 10px;
  max-height: 37px;
  object-fit: contain;
}
ul.features li::marker {
  color:var(--primary);
}
.modal-dialog.large {
  max-width: 750px;
}
ul.features li{
  margin-bottom: 5px;
}

.subscription-cards .plan .pm-card,.subscription-cards .plan .card-body {
  padding: 0;
}
.subscription-cards .sub-card > div.card-body {
  border-radius: 10px;
}
.subscription-cards .card-header {
  min-height: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: none;
  background: transparent;
  color: #fff;
}
.subscription-cards .card-header h3 {
  font-size: 2.5rem;
}
.subscription-cards .card-body .card {
  background: transparent;
  border: none;
}
.plan .sub-card .card-body {
  padding: 25px 0 ;
}
.main-content> .bg-light {
  background: #f2f2f2 !important;
  min-height: calc(100vh - 67px);
}
.pay input:focus {
  border-color: var(--primary) !important;
  box-shadow:none !important;
}
.premium .pm-card {
  background: var(--primary);
}
.subscription-cards .sub-card.premium .card-header {
  border-color: var(--primary);
}
.sub-card.premium .card-header{
  color:var(--primary);
}

.sub-card .list-group-item {
  border: none;
  position: relative;
  padding: 14px 10px 15px;
}

.sub-card .list-group-item:not(:last-child):after {
  content: "";
  background: #ccc;
  position: absolute;
  bottom: 0px;
  width: 70px;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
}

.order-detail {
  min-height: calc(100dvh - 165px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.mh-100-195 {
  min-height: calc(100vh - 195px);
}
.mh-100-120 {
  min-height: calc(100vh - 120px);
}
.subscription-cards .plan .card-body .card-body {
  padding: 20px 0 !important;
  background: var(--card-some_how_light-background);
  border: none;
  border-radius: 9px;
}
.free .pm-card {
  background: #858585;
}
.text-free {
  color: #ebebeb;
}
.sub-card.premium .list-group-item:after {
  background: var(--primary);
}

.order-detail td {
  padding: 3px 0;
}
.order-detail .img-thumbnail {
  width: 100px;
  min-width: 100px;
  height: 100px;
  object-fit: cover;
}
.subscription-cards .list-group li,.default-card  {
  border: none;
  position: relative;
  padding-left: 50px;
  text-align: left;
  color: #ebebeb;
  background-color: var(--card-some_how_light-background);
}
.default-card {
  white-space: nowrap;
}

.subscription-cards .list-group li:before, .default-card:before {
  content: "";
  background: #858585;
  position: absolute;
  left: 20px;
  top: 11px;
  width: 17px;
  height: 17px;
  border-radius: 20px;
}

.subscription-cards .list-group li:after, .default-card:after {
  content: "";
  width: 8px;
  height: 5px;
  border-left: 2px solid#fff;
  border-bottom: 2px solid #fff;
  position: absolute;
  left: 24px;
  top: 16px;
  transform: rotate(-43deg);
}

.default-card:before {
  top: 3px;
  background: var(--primary);
}

.default-card:after {
  top: 8px;
  left: 24.7px;
}
.plan .list-group {
  padding-bottom: 20px;
  min-height: 200px;
}
.btn.btn-md {
  padding: 0.5rem 1.3rem !important;
}
.btn-light {
  background: #f2f2f2;
  border: 1px solid #eaeaea;
}
.mb-01 {
  margin-bottom: 1px;
}
.subscription-cards .premium .list-group li:before{
  background: var(--primary);
}
.border-box.analytics-tbl td, .border-box.analytics-tbl th {
  padding: 12px 20px;
}
.border-box.analytics-tbl {
  min-height: 245px;
}
.border-box.analytics-tbl table {
  margin: 0;
  border-color: var(--card-some_how_light-background);
}
.border-box.analytics-tbl canvas {
  margin: 0 auto;
}
.analytics-line-chart canvas {
  max-height: 235px;
  min-height: 200px;
}
.custom-dp {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.card-brand{
  margin-left: 13px;
  background: #E8E8E8;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 20px;
}
.custom-dp .react-date-picker__wrapper {
  border: none;
}
.custom-dp .form-control {
  padding: 6.5px 7px 6.5px 15px !important;
  min-width: 150px;
  width: auto;
}
input:focus-visible {
    outline: none;
}
button.prev_btn {
  position: absolute;
  top: 23px;
  right: 55px;
  z-index: 99;
}
.date-range{
  max-width: 20px;
}

.subscription-status{
  background-color: #EB9A00;
  margin-left: 12px;
  padding: 6px 12px 6px 12px;
  border-radius: 12px;
  color: white;
  white-space: nowrap;
}

.upper-menu {
  width: 80%;
  position: absolute;
  z-index: 2;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  top: 20px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 10px 15px 10px 25px;
  max-width: 680px;
  transform: translateX(-50%);
}

.upper-menu li {
  display: inline-block;
  font-family: sans-serif;
  font-size: 16px;
}
.upper-menu img{
  height: 35px;
  width: auto;
  object-fit: contain;
}

.gyroscope {
  height: 40px;
  margin-top: 10px;
}

.viewer-controller-container{
  position: absolute;
  cursor: pointer;
  top: 3%;
  left: 15px;
  z-index: 10;
}

  .h-100-50 {
  height: calc(100% - 50px);
}
.share_people hr {
  color: #e8e8e8;
}

.upload-logo-restriction {
  font-size: 11px;
  position: relative;
  text-align: center;
  width: 70%;
  left: 89px;
}

.margin-bottom_-05rem {
  margin-bottom: -0.5rem
}

.font-bold-500 {
  font-weight: bold;
}

/* .dropdown-menu{
  background-color: var(--card-some_how_light-background);
  color: var(--light);
}  */

.form-control::placeholder{
  color: #808080;
}

.form-control:disabled {
  background-color: var(--card-some_how_light-background);
  opacity: 0.4;
}

.nav-tabs .nav-link {
  border: var(--card-background) solid transparent;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px var(--dark-background) inset !important;
  -webkit-text-fill-color: var(--light) !important;
}

.calendar {
  background-color: var(--card-some_how_more_light-background);
}

.empyy {
  color: red;
}

.white-color {
  color: #ebebeb !important;
}

/* Payment Element */

.light-background {
  background-color: var(--card-some_how_light-background);
} 


.dots {
  display: inline-block;
}

@keyframes dots {
  0%   { content: ''; }
  25%  { content: '.'; }
  50%  { content: '..'; }
  75%  { content: '...'; }
  100% { content: ''; }
}

.dots::after {
  content: '';
  animation: dots 1.5s steps(4, end) infinite;
}

/* responsive css  */
@media only screen and (min-width:1601px) {
  .pin-management .col-xl-3 {
    max-width: 20%;
  }
}

@media only screen and (min-width:1400px) {
  .assets_item .col-lg-3 {
    flex: 0 0 auto;
    width: 20%;
  }
  .subscription-cards >div.plan {
    min-width: 310px;
}
  .assets_image {
    height: 180px;
  }

  .assets_item .card.selected .assets_image {
    height: calc(180px - 16px);
  }

  .modal-xl .modal-body {
    padding: 35px;
  }

  .VR_rooms .col-xl-3,
  .userCard .col-lg-3 {
    max-width: 300px;
  }

  .VR_rooms .card-body {
    padding: 50px 15px;
  }
}
@media only screen and (min-width: 1200px) {
.border-box.analytics-tbl {
  min-height: 320px;
}
table .no-data {
  min-height: 245px;
}
.dashboard .text-center.no-data {
  height: calc( 100% - 45px);
}
}
@media only screen and (min-width: 991px) {
.virtual-tour-wrap.act .addtag button.tagButtonInfo_, .virtual-tour-wrap.act .addtag span.tagButtonInfo.tagButtonInfo_{
    left: calc(50% - 140px);
}
.w-lg-auto{
  width: auto !important;
}
}
@media only screen and (min-width: 768px) {

  button.btn.btn-long {
    min-width: 120px;
  }

  .orangeHeader h4 {
    padding-left: 10px;
  }

  .slick-slide {
    width: 168px !important;
  }
  .modal-footer .btn.btn-primary,
  .modal-footer .btn.btn-outline-secondary,
  .modal-footer .btn.btn-outline-primary {
    min-width: 160px;
    margin-bottom: 15px;
  }
  .modal-footer button.btn-link{
    margin-bottom: 15px;
  }
  .modal-medium .modal-footer .btn.btn-primary,
  .modal-medium .modal-footer .btn.btn-outline-secondary,
  .modal-medium .modal-footer .btn.btn-danger {
    min-width: 120px;
    margin-bottom: 15px;
  }

  .form-control,
  .form-select {
    padding: 0.575rem 1.3rem;
  }
}

@media only screen and (min-width: 577px) {
  
  .free .list-group {
    margin-bottom: 38px;
  }
  .create-virtul-tour-nav {
    border-bottom: 2px solid var(--card-background);;
  }
}
@media only screen and (max-width: 1400px) {
.no-content img {
  max-width: 300px;
}

}

@media only screen and (max-width: 1200px) {
  .headerbg {
    min-height: 11rem;
  }
}
@media only screen and (max-width: 991px) {
  .Counter_item {
    width: 50% !important;
    min-height: 65px;
  }
  .upper-menu{
    top: 15px;
    padding: 6px 6px 6px 20px;
    max-width: 300px;
  }
  button.prev_btn {
    right: 45px;
}
  .header-bottom {
    margin-top: -30px;
  }
  .header-innermenu {
    display:none;
  }
  .headerbg {
    min-height: 8.75rem;
    /* background: linear-gradient(310deg, rgba(33, 82, 255, 0), rgba(33, 212, 253, 0)) 50% center / cover, url(./../Images/xr/bg-mobile.jpg) transparent; */
    background: linear-gradient(310deg, rgba(33, 82, 255, 0), rgba(33, 212, 253, 0)) 50% center / cover, url(./../Images/Mobile-BG.jpg) transparent;
    border-radius: 1rem;
    background-position: 50% center;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .mh-100-195,.order-detail  {
    min-height: auto;
  }
  .backBtn {
    top: 10px;
    left: 10px;
  }

  .invite-screen {
    padding: 20px;
  }

  .modal-dialog.drop-modal.modal-lg {
    max-width: calc(100% - 60px);
    margin-left: 30px;
  }

  .modal-dialog.modal-xl {
    max-width: calc(100% - 15px);
  }


  .imageHeader h3 {
    font-size: 1.5rem;
  }

  .Dashboard_Counter {
    flex-wrap: wrap;
  }

  .Counter_item:nth-child(2),
  .Counter_item:nth-child(1) {
    margin-bottom: 50px;
  }

  .Counter_item:nth-child(3) {
    border-left: none;
  }

  .modal-dialog.drop-modal.modal-md {
    max-width: 600px;
  }
}

@media only screen and (max-width: 767.5px) {

  .Three-sixty-icon-overlay{
    position: relative;
    height: 0px;
    z-index: 5;
    top: 53px;
  }

  .userName {
    font-size: 24px;
  }
  button.prev_btn{
    top: 17px;
  }
  .collapseBtn {
      top: 50%;
      /* top: 15px; */
  }
  .upper-menu{
    max-width: 300px;
  }
  .viewer-controller-container{
    top: 10%
  }
  .border-box.analytics-tbl {
    min-height: 245px;
  }
  .inner-wrap {
      padding: 0 25px;
  }
  .tour-menu-list {
    width: 100%;
  }
  .white-box {
      padding: 15px;
  }
  .headerbg {
    min-height: 10rem;
  }

  h2.header-title {
    top: 5rem;
  }
  .login-content{
    display: none;
  }

  .auth-background-image{
    background-image: none;
  }

  header.header {
    padding: 0.8rem 0 0.2rem;
    box-shadow: 0px 0px 10px -3px #0002;
    margin-bottom: 20px;
  }
  .header-innermenu li {
    width: 100%;
    margin-top: 10px;
  }

  .drawer-wrap hr {
    margin-bottom: 0;
  }

  .no-content img {
    max-width: 250px;
  }

  .assets_item .card video {
    height: auto !important;
  }

  .modal-dialog .tab-content .tab-pane {
    max-height: calc(100vh - 350px);
  }

  .asset_filter {
    width: 100%;
    float: none;
    margin: 10px 0 !important;
  }

  ul.nav.nav-tabs {
    flex-wrap: nowrap;
    overflow: auto;
  }

  .shared_item .slick-list {
    padding: 0 !important;
  }

  .shared_item .slick-slide {
    max-width: 168px;
  }

  .orangeHeader h4 {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  .login-form {
    background: var(--body-bg);
  }

  .password-field span {
    padding: 11px;
  }

  a.navbar-brand {
    width: calc(100% - 60px);
    text-align: center;
    margin-right: 0;
  }

  .sideDrawer {
    transform: translateX(-300px);
    transition: all ease 0.3s;
  }
  
  .main-content {
    margin-left: 0px;
    padding: 0px 0px 15px;

  }
  /* .panorama.pnlm-container {
      height: calc(100dvh - 62px) !important;
  } */

  .shared_item.dashboard_card,
  .Library.dashboard_card,
  .Dashboard_Counter {
    margin-left: 20px;
    margin-right: 20px;
  }

  .sideDrawer.active {
    transform: translateX(0px);
  }

  .modal-footer .btn {
    min-width: calc(50% - 20px);
  }

  .login-form,
  .login-content {
    min-height: calc(100vh - 60px);
    padding: 0;
    width: 100%
  }
  .container-fluid.login-container .row {
    margin: 0;
  }
  .change_password {
    margin: 0 auto;
  }

  a.navbar-brand img {
    margin-right: 60px;
  }

  .drawerBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #000;
    height: 100%;
    z-index: 8;
    opacity: 0;
    transform: translateX(-100%);
  }

  .sideDrawer.active+.drawerBackdrop {
    transform: translateX(0);
    opacity: 0.4;
  }
  .modal-wrapper-thankyou {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35vh;
    text-align: center;
    width: 78%;
    margin-left: 10%;
    margin-right: 10px;
    margin-top: 50%;
  }

  .modal-dialog.drop-modal.modal-md {
    max-width: calc(100% - 40px);
  }
  .viewr-page .simple-header header.header {
    padding: 0 !important;
    box-shadow: none;
}

  .btn.btn-me-cancel {
    background: #fff;
    color: #6c757d;
    border-color: #6c757d;
    min-width: 160px;
    min-width: calc(50% - 20px);
    margin: 4px 4px;
  }
}


/* @media (max-width: 740.5px) {

  .upper-menu{
    max-width: 300px;
  }

  .viewer-controller-container {
    top: 10%
  }

} */


@media only screen and (max-width: 576px) {

  /* .btn.btn-me-cancel {
    background: #fff;
    color: #6c757d;
    border-color: #6c757d;
    min-width: 160px;
    min-width: calc(50% - 20px);
  } */

  .upload-logo-restriction {
    font-size: 11px;
    position: relative;
    text-align: center;
    width: 70%;
    left: 48px;
  }

  .modal-dialog.drop-modal {
    max-width: calc(100% - 55px);
    margin-left: 25px;
  }
  .modal-dialog.outside-close-modal {
    margin: 30px;
}
  .edit_profile {
    max-width: 400px;
    margin: 0 auto;
  }
  .headerbg {
    min-height: 7rem;
}.header-bottom {
  margin-top: -25px;
}h2.header-title {
  top: 3rem;
}

  .imageModal li[role="button"] {
    width: 8px;
    height: 8px;
    min-width: 8px;
  }

  .inner_lists {
    max-height: calc(100vh - 202px);
    overflow-y: auto;
  }


  li.create-virtul-tour-nav-itme.nav-item a {
    min-width: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
  .virtualMdl .modal-body {
    padding: 15px;
}
}

@media only screen and (max-width: 576px) and (min-width:480px) {
  .userInfo {
    max-width: 450px;
    margin: 0 auto;
  }

  .VR_rooms .col-xl-3,
  .userCard .col-lg-3 {
    max-width: 50%;
  }

  .assets_image {
    height: 300px;
  }

  .assets_item .card.selected .assets_image {
    height: calc(300px - 16px);
  }

  .pin-management .col-xl-3 {
    max-width: 50%;
  }
}

@media only screen and (max-width: 576px) {
  .add-input-boxes-container_lable_1 {
    width: 50% !important;
  }
  .border-box.analytics-tbl {
    min-height: auto;
}
  .cancel-input-boxes-container {
    /* height: 24px; */
    cursor: pointer;
    display: contents;
  }

  .myDiv {
    width: 300px;
    padding: 15px 15px 20px;
    top: 55px;
    left: -10px;
  }
  .myDiv:after {
      top: -6px;
      left: 20px;
  }
  span.tagButtonInfo {
      padding: 10px 15px;
      font-size: 12px;
      margin-bottom: 10px;
  }
  .addtag {
      bottom: 10px;
  }
  .cancel-input-boxes-container img {
    margin-top: 10px;
    height: 15px;
  }
.myDiv .btn {
    margin-top: 0px !important;
}
.upper-menu{
  top: 50px;
}
}

@media only screen and (max-width: 480px) {
  .assets_image {
    height: 200px;
  }
  .addtag span.tagButtonInfo{
    min-width: 300px;
    font-size: 12px;
    padding: 10px 10px;
    bottom: 50px;
  }
  inputUploader {
    padding: 13px;
}
.dzu-dropzone {
  height: 250px !important;
}
.virtual-tour-and-img .card-body img {
  height: 175px;
}

}